<template>
  <div id="add-supplier-users">
    <v-container>
      <h1 class="mt-4">Add Supplier User Details</h1>
      <SupplierUsersForm
        :initial-supplier-user="supplierUser"
        @save="createSupplierUser"
        :is-view="false"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import SupplierUsersForm from '@/views/SupplierUsers/form'
import { DEFAULT_SUPPLIER_USER } from '@/constants/initial'
import { Alert } from '@nswdoe/doe-ui-core'
import { cloneDeep } from 'lodash'

export default {
  name: 'AddSupplierUsers',
  title: 'Add Supplier Users - SAIS Operation UI Portal',
  components: {
    SupplierUsersForm,
    Alert,
  },
  data() {
    return {
      supplierUser: cloneDeep(DEFAULT_SUPPLIER_USER),
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        type: 'success',
      },
    }
  },
  methods: {
    createSupplierUser(item) {
      console.log(item)
      this.$store
        .dispatch('moduleSupplierUsers/postSupplierUser', {
          status: 'Active',
          mfaEnabled: 'Enabled',
          ...item,
        })
        .then((response) => {
          this.saveClicked = {
            isClicked: true,
            type: response.isError ? 'error' : 'success',
            isSuccess: !response.isError,
            message: response.isError
              ? response.errorMessage
              : 'The changes have been saved successfully',
          }

          if (!response.isError) {
            this.$router.push('/serviceregistry/supplierUsers')
          }
        })
    },
  },
}
</script>
