<template>
  <v-form ref="supplierUsersForm">
    <v-card class="pa-6 my-8">
      <AdsSelect
        v-model="supplierUser.supplierId"
        :items="supplierItems"
        class="mb-0"
        outlined
        label="Supplier"
        placeholder="Please select..."
        :rules="[validators.required]"
        :disabled="isView || isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplierUser.firstName"
        label="Given Name"
        maxlength="200"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplierUser.lastName"
        label="Surname"
        maxlength="200"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplierUser.emailAddress"
        label="Email"
        maxlength="200"
        :rules="[validators.email, validators.emailDomainAllowed(supplierEmailDomains)]"
        :disabled="isReadOnlyUser || isView"
        :hint="`Email domain must be one of the following: ${supplierEmailDomains.join(', ')}`"
      />
      <AdsSelect
        v-model="supplierUser.status"
        :items="statusItems"
        class="mb-0"
        outlined
        label="Status"
        placeholder="Please select..."
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
        v-if="isView"
      />
      <AdsSelect
        v-model="supplierUser.mfaEnabled"
        :items="mfaItems"
        class="mb-0"
        outlined
        label="MFA Enabled"
        placeholder="Set user's MFA settings"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
        v-if="isView"
      />
      <AdsSelect
        v-model="supplierUser.role"
        :items="role"
        class="mb-0"
        outlined
        label="Role"
        placeholder="Set user's role"
        :rules="[validators.required]"
        :readonly="isReadOnlyUser"
      />
      <AdsTextField
        v-model="supplierUser.mobileNumber"
        label="Mobile No"
        maxlength="14"
        hint="please include country code, E.g., +61412345678"
        :rules="[validators.required, validators.phoneStartWithPlus]"
        :readonly="isReadOnlyUser"
      />
    </v-card>
    <AdsButton
      :primary="true"
      buttonText="Save"
      class="mr-4"
      @click="save"
      :disabled="isReadOnlyUser"
    />
    <AdsButton
      :primary="true"
      buttonText="Cancel"
      @click="cancel"
    />
  </v-form>
</template>
<script>
import { AdsTextField, AdsSelect, AdsButton } from '@nswdoe/doe-ui-core'
import { DEFAULT_SUPPLIER_USER } from '@/constants/initial'
import validators from '@/helpers/validators'
import { getFormErrors } from '@/helpers/form'
import { cloneDeep } from 'lodash'

export default {
  name: 'AddSupplierUsers',
  components: {
    AdsTextField,
    AdsSelect,
    AdsButton,
  },
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
    initialSupplierUser: {
      type: Object,
      default: cloneDeep(DEFAULT_SUPPLIER_USER),
    },
  },
  data() {
    return {
      supplierUser: this.initialSupplierUser,
      errors: [],
      showErrors: false,
      statusItems: [
        {
          text: 'Active',
          value: 'Active',
        },
        {
          text: 'Inactive',
          value: 'Deprecated',
        },
      ],
      role: ['Admin', 'ReadOnly'],
      mfaItems: ['Enabled', 'Disabled'],
      supplierItems: [],
      validators: {
        required: validators.required,
        alpha: validators.alpha,
        phone: validators.phone,
        phoneStartWithPlus: validators.phoneStartWithPlus,
        email: validators.email,
        alphaNumeric: validators.alphaNumeric,
        isValidURL: validators.isValidURL,
        emailDomainAllowed: validators.emailDomainAllowed,
      },
    }
  },
  created() {
    this.$store.dispatch('moduleSuppliers/fetchSuppliers').finally(() => {
      this.supplierItems = this.$store.state.moduleSuppliers.suppliers
        .filter((s) => s.status !== 'Deprecated')
        .map((item) => {
          return {
            text: item.name,
            value: item.supplierId,
          }
        })
    })
  },
  computed: {
    isAdmin() {
      return this.$store.state.isAdmin
    },
    isReadOnlyUser() {
      return this.$store.state.isReadOnlyUser
    },
    supplierEmailDomains: {
      get() {
        const supplier = this.$store.state.moduleSuppliers.suppliers.find(
          (supplier) => supplier.supplierId === this.supplierUser.supplierId
        )

        return supplier ? supplier.emailDomains : []
      },
      set() {
        // This setter is empty because we only want to react to changes in supplierId
      },
    },
  },
  methods: {
    cancel() {
      this.$router.push('/serviceregistry/supplierUsers')
    },
    save() {
      this.validate()
      this.errors = getFormErrors(this.$refs.supplierUsersForm)

      if (this.errors && this.errors.length > 0) {
        this.showErrors = true
      } else {
        // No validation errors
        this.$emit('save', this.supplierUser)
      }
    },
    validate() {
      if (this.$refs.supplierUsersForm) {
        this.$refs.supplierUsersForm.validate()
      }
    },
  },
}
</script>
